import { saveAs } from 'file-saver';


export const blobToFile = (blob, fileName) => {
  return new File([blob], fileName, {
    lastModified: new Date().getTime(),
    type: blob.type,
  });
};

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || "");
    reader.onerror = (error) => reject(error);
  });


export const convertBinaryToExcel = async (binaryData, fileName = 'generated_excel') => {
  // try {

  //   const blob = new Blob([binaryData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  //   // Create a download link
  //   const downloadLink = document.createElement('a');
  //   downloadLink.href = window.URL.createObjectURL(blob);

  //   downloadLink.download = `${fileName}.xlsx`;
  //   await document.body.appendChild(downloadLink);

  //   await downloadLink.click();
  // } catch (error) {
  //   console.error('Error converting binary to Excel:', error);
  // }

  try {
    console.log("binaryData", binaryData.length)
    const blob = new Blob([binaryData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Save the Blob as a file using file-saver
    saveAs(blob, fileName);
  } catch (error) {
    console.error('Error converting binary to Excel:', error);
  }
};

export const hasCommonScope = (routerScopes, userScopes) => {
  for (const scope of userScopes) {
    if (routerScopes?.includes(scope)) {
      // console.log('TESTT Permissions', routerScopes, userScopes, scope, routerScopes.includes(scope))
      return true;
    }
  }

  return false;
};
