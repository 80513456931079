export const PERMISSION_USER_READ = "user-read";
export const PERMISSION_USER_WRITE = "user-write";
export const PERMISSION_SUPPORTED_METHOD_READ = "supported-payment-method-read";
export const PERMISSION_SUPPORTED_METHOD_WRITE = "supported-payment-method-write";
export const PERMISSION_SMS_TRANSACTION_READ = "sms-transaction-read";
export const PERMISSION_SMS_TRANSACTION_WRITE = "sms-transaction-write";
export const PERMISSION_PRODUCT_READ = "product-read";
export const PERMISSION_PRODUCT_WRITE = "product-write";
export const PERMISSION_BILL_HISTORY_READ = "bill-history-read";
export const PERMISSION_BILL_HISTORY_WRITE = "bill-history-write";
export const PERMISSION_BILL_READ = "bill-read";
export const PERMISSION_BILL_WRITE = "bill-write";
export const PERMISSION_TOP_UP = "top-up";
export const PERMISSION_BILL_COMPLETION = "bill-completion";