import { useState } from "react";
import { useNavigate } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import LogoutIcon from "@mui/icons-material/Logout";

import { useUser } from "../../contexts/UserContext";
import { logout } from "../../services/authService";

const LogoutButton = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { removeUser } = useUser();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      setIsLoading(true);

      await logout();
    } catch (error) {
    } finally {
      removeUser();

      setIsLoading(false);

      navigate("/");
    }
  };

  return (
    <>
      <IconButton
        color="#000"
        aria-label="logout"
        edge="end"
        onClick={handleLogout}
      >
        <LogoutIcon />
      </IconButton>

      {isLoading && (
        <Backdrop
          aria-hidden="false"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress size={40} />
        </Backdrop>
      )}
    </>
  );
};

export default LogoutButton;
