// import axios from "axios";

// import { getAccessToken } from "../services/authService";

// const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
// const whishBaseUrl = process.env.WHISH_ONLINE_PAYMENT_BASE_URL;

// const axiosInstance = axios.create({
//   //set a variable where it saves the function ? baseUrl : whishBaseUrl
//   // set a variable when running the function , set it back when the login runs on initialization, and in the public useEffect
//   // and add the headers in the api call
//   baseURL: baseUrl,
// });

// const customAxios = (useBaseUrl = true) => {
//   // const whishBaseUrl = "https://lb.sandbox.whish.money/itel-service/api"

//   const axiosInstance = axios.create({
//     baseURL: useBaseUrl ? "https://quick-pay-4413e3b63392.herokuapp.com" : "https://lb.sandbox.whish.money/itel-service/api"
//   });

//   const accessToken = getAccessToken();
//   console.log('accessToken', accessToken)

//   return axiosInstance;
// };

// customAxios().interceptors.request.use(
//   (config) => {
//     if (config.headers) {
//       const accessToken = getAccessToken();
//       config.headers["secret"] = process.env.WHISH_ONLINE_PAYMENT_SECRET
//       config.headers["websiteurl"] = process.env.WHISH_ONLINE_PAYMENT_WEBSITE_URL
//       config.headers["channel"] = process.env.WHISH_ONLINE_PAYMENT_CHANNEL
//       // config.headers["secret"] = "2f3a413ae35047df95e56986ff1957dd"
//       // config.headers["websiteurl"] = "quicqpay.com"
//       // config.headers["channel"] = 10192113

//       if (accessToken) {
//         config.headers["Authorization"] = "Bearer " + accessToken;
//       }
//     }

//     return config;
//   },
//   (error) => {
//     console.log('erroroo', error)
//     Promise.reject(error);
//   }
// );


// export { axiosInstance, customAxios };


import axios from "axios";
import { getAccessToken } from "../services/authService";

const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL
const whishBaseUrl = process.env.REACT_APP_WHISH_ONLINE_PAYMENT_BASE_URL

// const axiosInstance = axios.create({
//   baseURL: baseUrl,
// });

const customAxios = (useBaseUrl = true) => {
  const instance = axios.create({
    baseURL: useBaseUrl
      ? baseUrl
      : whishBaseUrl,
  });

  instance.interceptors.request.use(
    (config) => {
      config.headers["secret"] = process.env.REACT_APP_WHISH_ONLINE_PAYMENT_SECRET;
      config.headers["websiteurl"] = process.env.REACT_APP_WHISH_ONLINE_PAYMENT_WEBSITE_URL;
      config.headers["channel"] = process.env.REACT_APP_WHISH_ONLINE_PAYMENT_CHANNEL;
      if (config.headers) {
        const accessToken = getAccessToken();

        if (accessToken) {
          config.headers["Authorization"] = "Bearer " + accessToken;
        }
      }

      return config;
    },
    (error) => {
      console.log("erroroo", error);
      return Promise.reject(error);
    }
  );

  return instance;
};

export { customAxios };


