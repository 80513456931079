import { RouterProvider } from "react-router-dom";
import publicRouter from "./config/publicRouter";
import adminRouter from "./config/adminRouter";
import superAdminRouter from "./config/superAdminRouter";
import { useUser } from "./contexts/UserContext";

const App = () => {
  const { user } = useUser();
  if (user) {
    if (user.isAdmin) {
      return <RouterProvider router={adminRouter} />;
    } else if (user.isSuperAdmin) {
      return <RouterProvider router={superAdminRouter} />;
    }
  }
  return <RouterProvider router={publicRouter} />;
};
export default App;
