import { createTheme } from "@mui/material/styles";

export const PRIMARY = "#336676";
// export const PRIMARY = "#1860aa";
export const SECONDARY = "#F9F9F9";
export const LIGHT = "#73CEE7";
export const RED = "#FF0000";

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: PRIMARY,
    },
    secondary: {
      main: SECONDARY,
    },
    light: {
      main: LIGHT,
    },
    red: {
      main: RED,
    },
  },
});

export default muiTheme;
