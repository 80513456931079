import { lazy, Suspense } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Outlet,
  useLocation
} from "react-router-dom";

import ResponsiveDrawer from "../components/ResponsiveDrawer";
import Loader from "../components/Loader";
import { useUser } from "../contexts/UserContext";

const NotFound = lazy(() => import("../pages/NotFound"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const ViewResellers = lazy(() => import("../pages/Resellers/ViewResellers"));
const Users = lazy(() => import("../pages/users/UsersClient"));
const Bills = lazy(() => import("../pages/Bills/BillsView"));
const Transaction = lazy(() => import("../pages/Transaction"));
const Notification = lazy(() =>
  import("../pages/Notifications/NotficationView")
);
const AddUser = lazy(() => import("../pages/users/AddUser"));
const AddReseller = lazy(() => import("../pages/Resellers/AddReseller"));
const AddBill = lazy(() => import("../pages/Bills/addBill"));
const ViewProduct = lazy(() => import("../pages/Products/ViewProduct"));
const AddProduct = lazy(() => import("../pages/Products/addProducts"));
const SendSms = lazy(() => import("../pages/Notifications/sendSms"));
const ViewPermissions = lazy(() =>
  import("../pages/Permissions/ViewPermission")
);
const AddPermission = lazy(() => import("../pages/Permissions/AddPermission"));
const ViewSettings = lazy(() => import("../pages/Settings/ViewSettings"));


const allowedRoutes = [
  "/",
  "/dashboard",
  "/resellers",
  // "/users",
  // "/teams",
  // "/profile",
  // "/profile/add",
  // "/profile/:profileCode",
  // "/profile/:profileCode/edit",
];


const Root = () => {
  const location = useLocation();
  console.log("useUser", useUser, useUser().user.isAdmin);
  const { user } = useUser();
  const isAdmin = user.isAdmin;

  // return (
  //   <ResponsiveDrawer>
  //     <Suspense fallback={<Loader />}>
  //       <Outlet />
  //     </Suspense>
  //   </ResponsiveDrawer>
  // );

  // if (user) {
  if (allowedRoutes.some((route) => location.pathname.startsWith(route))) {
    return (
      <ResponsiveDrawer>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </ResponsiveDrawer>
    );
  } else {
    return (
      <Suspense fallback={<Loader />}>
        <Outlet />
      </Suspense>
    );
  }
  // }
};

const adminRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />}>
      <Route index element={<Dashboard />} />

      <Route path="users" element={<Users />} />
      <Route path="users/add-user" element={<AddUser />} />
      <Route path="bills" element={<Bills />} />
      <Route path="bills/add-bill" element={<AddBill />} />
      <Route path="resellers" element={<ViewResellers />} />
      <Route path="resellers/add-reseller" element={<AddReseller />} />
      <Route path="transaction" element={<Transaction />} />
      <Route path="notification" element={<Notification />} />
      <Route path="Products" element={<ViewProduct />} />
      <Route path="Products/add-product" element={<AddProduct />} />
      <Route path="notification/send-sms" element={<SendSms />} />
      <Route path="permission" element={<ViewPermissions />} />
      <Route path="permission/add-permission" element={<AddPermission />} />
      <Route path="settings" element={<ViewSettings />} />

      <Route key="not-found" path="*" element={<NotFound location="admin" />} />
    </Route>
  )
);

export default adminRouter;
