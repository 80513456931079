import { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useUser } from "../../contexts/UserContext";
import {
  PERMISSION_USER_READ, PERMISSION_USER_WRITE, PERMISSION_SUPPORTED_METHOD_READ,
  PERMISSION_SUPPORTED_METHOD_WRITE, PERMISSION_SMS_TRANSACTION_READ, PERMISSION_SMS_TRANSACTION_WRITE,
  PERMISSION_PRODUCT_READ, PERMISSION_PRODUCT_WRITE, PERMISSION_BILL_HISTORY_READ,
  PERMISSION_BILL_HISTORY_WRITE, PERMISSION_BILL_READ, PERMISSION_BILL_WRITE,
  PERMISSION_TOP_UP, PERMISSION_BILL_COMPLETION
} from "../../config/userPremission";
import { SUPERUSER, ADMIN, RESELLER } from "../../config/userScope";
import { hasCommonScope } from "../../helpers";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// New Imports icons
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AdminPanelIcon from "@mui/icons-material/AdminPanelSettings";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import CampaignIcon from "@mui/icons-material/Campaign";
// new import css style
import "./responsiveDrawer.css";
import { PRIMARY, SECONDARY } from "../../config/muiTheme";
import LogoutButton from "../LogoutButton";
import logo from "../../assets/images/QuicqPay-Logo.png";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import InventoryIcon from "@mui/icons-material/Inventory";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import SettingsIcon from "@mui/icons-material/Settings";
import { Grid } from "@mui/material";

const drawerWidth = 240;

const ResponsiveDrawer = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navigationList = [
    {
      name: "Dashboard",
      icon: <HomeIcon />,
      link: "/",
      hasDivider: false,
      scopes: [SUPERUSER, ADMIN, RESELLER],
      permissions: [PERMISSION_USER_READ, PERMISSION_USER_WRITE, PERMISSION_SUPPORTED_METHOD_READ,
        PERMISSION_SUPPORTED_METHOD_WRITE, PERMISSION_SMS_TRANSACTION_READ, PERMISSION_SMS_TRANSACTION_WRITE,
        PERMISSION_PRODUCT_READ, PERMISSION_PRODUCT_WRITE, PERMISSION_BILL_HISTORY_READ,
        PERMISSION_BILL_HISTORY_WRITE, PERMISSION_BILL_READ, PERMISSION_BILL_WRITE,
        PERMISSION_TOP_UP, PERMISSION_BILL_COMPLETION],
    },
    {
      name: "Admin",
      icon: <AdminPanelIcon />,
      forSuperAdmin: true,
      scopes: [SUPERUSER],
      link: "admins",
      hasDivider: false,
      permissions: [PERMISSION_USER_READ, PERMISSION_USER_WRITE, PERMISSION_SUPPORTED_METHOD_READ,
        PERMISSION_SUPPORTED_METHOD_WRITE, PERMISSION_SMS_TRANSACTION_READ, PERMISSION_SMS_TRANSACTION_WRITE,
        PERMISSION_PRODUCT_READ, PERMISSION_PRODUCT_WRITE, PERMISSION_BILL_HISTORY_READ,
        PERMISSION_BILL_HISTORY_WRITE, PERMISSION_BILL_READ, PERMISSION_BILL_WRITE,
        PERMISSION_TOP_UP, PERMISSION_BILL_COMPLETION],
    },
    {
      name: "Users",
      icon: <ManageAccountsIcon />,
      forSuperAdmin: true,
      link: "admin-view-users",
      hasDivider: false,
      scopes: [SUPERUSER],
      permissions: [PERMISSION_USER_READ, PERMISSION_USER_WRITE],
    },
    {
      name: "User Client",
      icon: <PersonIcon />,
      link: "users",
      hasDivider: false,
      scopes: [SUPERUSER, ADMIN, RESELLER],
      permissions: [PERMISSION_USER_READ, PERMISSION_USER_WRITE, PERMISSION_SUPPORTED_METHOD_READ,
        PERMISSION_SUPPORTED_METHOD_WRITE, PERMISSION_SMS_TRANSACTION_READ, PERMISSION_SMS_TRANSACTION_WRITE,
        PERMISSION_PRODUCT_READ, PERMISSION_PRODUCT_WRITE, PERMISSION_BILL_HISTORY_READ,
        PERMISSION_BILL_HISTORY_WRITE, PERMISSION_BILL_READ, PERMISSION_BILL_WRITE,
        PERMISSION_TOP_UP, PERMISSION_BILL_COMPLETION],
    },
    {
      name: "Bills",
      icon: <AccountBalanceWalletIcon />,
      link: "bills",
      hasDivider: false,
      scopes: [SUPERUSER, ADMIN, RESELLER],
      permissions: [PERMISSION_BILL_READ, PERMISSION_BILL_WRITE],
    },
    {
      name: "Resellers",
      icon: <PeopleIcon />,
      link: "resellers",
      hasDivider: false,
      scopes: [SUPERUSER, ADMIN, RESELLER],
      permissions: [PERMISSION_USER_READ, PERMISSION_USER_WRITE, PERMISSION_SUPPORTED_METHOD_READ,
        PERMISSION_SUPPORTED_METHOD_WRITE, PERMISSION_SMS_TRANSACTION_READ, PERMISSION_SMS_TRANSACTION_WRITE,
        PERMISSION_PRODUCT_READ, PERMISSION_PRODUCT_WRITE, PERMISSION_BILL_HISTORY_READ,
        PERMISSION_BILL_HISTORY_WRITE, PERMISSION_BILL_READ, PERMISSION_BILL_WRITE,
        PERMISSION_TOP_UP, PERMISSION_BILL_COMPLETION],
    },
    {
      name: "Products",
      icon: <InventoryIcon />,
      link: "Products",
      hasDivider: false,
      scopes: [SUPERUSER, ADMIN, RESELLER],
      permissions: [PERMISSION_PRODUCT_READ, PERMISSION_PRODUCT_WRITE],
    },
    {
      name: "Transaction History",
      icon: <MenuBookIcon />,
      link: "transaction",
      hasDivider: false,
      scopes: [SUPERUSER, ADMIN, RESELLER],
      permissions: [PERMISSION_BILL_HISTORY_READ, PERMISSION_BILL_HISTORY_WRITE],
    },
    {
      name: "Notifications",
      icon: <CampaignIcon />,
      link: "notification",
      hasDivider: false,
      scopes: [SUPERUSER, ADMIN, RESELLER],
      permissions: [PERMISSION_SMS_TRANSACTION_READ, PERMISSION_SMS_TRANSACTION_WRITE,],
    },
    {
      name: "Permissions",
      icon: <LockPersonIcon />,
      link: "permission",
      hasDivider: false,
      scopes: [SUPERUSER, ADMIN, RESELLER],
      permissions: [PERMISSION_USER_READ, PERMISSION_USER_WRITE, PERMISSION_SUPPORTED_METHOD_READ,
        PERMISSION_SUPPORTED_METHOD_WRITE, PERMISSION_SMS_TRANSACTION_READ, PERMISSION_SMS_TRANSACTION_WRITE,
        PERMISSION_PRODUCT_READ, PERMISSION_PRODUCT_WRITE, PERMISSION_BILL_HISTORY_READ,
        PERMISSION_BILL_HISTORY_WRITE, PERMISSION_BILL_READ, PERMISSION_BILL_WRITE,
        PERMISSION_TOP_UP, PERMISSION_BILL_COMPLETION],
    },
    {
      name: "Settings",
      icon: <SettingsIcon />,
      link: "settings",
      hasDivider: false,
      scopes: [SUPERUSER, ADMIN, RESELLER],
      permissions: [PERMISSION_USER_READ, PERMISSION_USER_WRITE, PERMISSION_SUPPORTED_METHOD_READ,
        PERMISSION_SUPPORTED_METHOD_WRITE, PERMISSION_SMS_TRANSACTION_READ, PERMISSION_SMS_TRANSACTION_WRITE,
        PERMISSION_PRODUCT_READ, PERMISSION_PRODUCT_WRITE, PERMISSION_BILL_HISTORY_READ,
        PERMISSION_BILL_HISTORY_WRITE, PERMISSION_BILL_READ, PERMISSION_BILL_WRITE,
        PERMISSION_TOP_UP, PERMISSION_BILL_COMPLETION],
    },
  ];

  const showBackArrowRoutes = [
    "/admins/add-admin",
    "/users/add-user",
    "/bills/add-bill",
    "/resellers/add-reseller",
    "/products/add-product",
    "/notification/send-sms",
    "/permission/add-permission",
  ];

  const showBackArrow = showBackArrowRoutes.includes(location.pathname);

  const currentPath = location.pathname;

  navigationList.forEach((item) => {
    item.isActive = currentPath === item.link;
  });

  const { user } = useUser();

  console.log('user.permissions', user.permissions)

  const drawer = (
    <Box>
      <Toolbar sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          component="img"
          width="200px"
          height="auto"
          alt="Computer login"
          src={logo}
        />
      </Toolbar>

      <List>
        {navigationList.map(
          (element, index) =>
            // (user.isSuperAdmin || !element.forSuperAdmin) &&
            // (hasCommonScope(element.scopes, user.scopes)) && (
            (hasCommonScope(element.scopes, user.scopes)) && (user.isSuperAdmin || hasCommonScope(element.permissions, user.permissions)) && (
              <Fragment key={index}>
                <ListItem
                  className="list-item-res-drawer active "
                  disablePadding
                  onClick={() => {
                    navigate(element.link);
                    setMobileOpen(false);
                  }}
                  sx={{ color: PRIMARY }}
                >
                  <ListItemButton>
                    <ListItemIcon
                      sx={{ color: PRIMARY }}
                      className="list-item-icon-nav"
                    >
                      {element.icon}
                    </ListItemIcon>

                    <ListItemText primary={element.name} />
                  </ListItemButton>
                </ListItem>

                {element.hasDivider && (
                  <Divider
                    sx={{
                      my: 1,
                    }}
                  />
                )}
              </Fragment>
            )
        )}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  const screenTitleName = location.pathname.substring(1).toLowerCase(); //change it from toUpperCase to toLowerCase

  return (
    <Box sx={{ display: "flex" }} className="main-box-res-drawer">
      <CssBaseline />

      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 12px",
          }}
          className="appbar-res-drawer"
        >
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          {/* div to add them next each other */}
          <Grid
            container
            alignItems="center"
            justifyContent="flex-start"
            className="show-arrow-back"
          >
            {showBackArrow && (
              <IconButton onClick={() => navigate(-1)} aria-label="Go back">
                <ArrowBackIcon />
              </IconButton>
            )}

            <Typography
              component="p"
              // variant="h6"
              noWrap
              className="screenTitleName"
              sx={{
                textTransform: "capitalize",
                fontSize: "14px",
                color: "#000",
              }}
            >
              {screenTitleName}
            </Typography>
          </Grid>

          <Grid
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                // marginRight: "5vh",
                // marginTop: "0.5vh",
                color: "#000",
                fontSize: "14px",
              }}
              component="p"
              variant="h6"
              noWrap
              className="screenTitleName"
            >
              Hello {user.username}
            </Typography>

            <LogoutButton className="log-out-button" />
          </Grid>
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          zIndex: (theme) => theme.zIndex.drawer - 101,
        }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: SECONDARY,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: 3,
          pt: 9,
          pb: 6,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          minHeight: "100vh",
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default ResponsiveDrawer;
