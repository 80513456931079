import { lazy, Suspense } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Outlet,
} from "react-router-dom";
import Loader from "../components/Loader";

const Login = lazy(() => import("../pages/auth/Login"));
const NotFound = lazy(() => import("../pages/NotFound"));
const UserPayment = lazy(() => import("../pages/Public/UserPayment/index"));
const TopUp = lazy(() => import("../pages/Public/TopUp/index"));
const PaymentStatus = lazy(() => import("../pages/Public/paymentStatus/index"));

const Root = () => {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Outlet />
      </Suspense>
    </>
  );
};

const publicRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />}>
      <Route index element={<Login />} />
      <Route path="/public/bill-details/:billId" element={<UserPayment />} />
      <Route path="/public/topup/:adminId" element={<TopUp />} />
      <Route
        path="/public/paymentSuccess/:paymentStatus/:transactionId"
        element={<PaymentStatus />}
      />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

export default publicRouter;
