import { useState, createContext, useContext } from "react";

const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user") || null)
  );

  const updateUser = (loggedUser) => {
    if (loggedUser && (loggedUser.isAdmin || loggedUser.isSuperAdmin)) {
      setUser(loggedUser);

      localStorage.setItem("user", JSON.stringify(loggedUser));
    }
  };

  const removeUser = () => {
    localStorage.removeItem("user");

    setUser(null);
  };

  const value = {
    user,
    updateUser,
    removeUser,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
