import { axiosInstance, customAxios } from "../config/axiosInstance";
import { toast } from "react-toastify";

const ADMIN = "admin";
const RESELLER = "reseller";
const SUPERADMIN = "super-user";

export const login = async (username, password) => {
  try {
    const response = await customAxios().post("/user/login", {
      username,
      password,
    });


    let user = {};

    if (response.data && response.data.error) {
      if (response.data.error.data?.invalid) {
        toast(response.data.error.message, { type: "warning" });
      } else {
        toast(response.data.error.message.toString(), { type: "error" });
      }


      return null;
    }


    if (response.data && response.data.success) {
      if (response.data.success.data.user) {
        if (response.data.success.data.user.scopes) {
          let scopes = response.data.success.data.user.scopes;

          if (scopes.includes(ADMIN) || scopes.includes(RESELLER)) {
            user.isAdmin = true;
          } else if (scopes.includes(SUPERADMIN)) {
            user.isSuperAdmin = true;
          } else {
            user.isAdmin = false;
          }
        }

        user.scopes = response.data.success.data.user.scopes;
        user.username = response.data.success.data.user.username;
        user.email = response.data.success.data.user.email;
        user.permissions = response.data.success.data.user.permissions;
      }

      if (response.data.success.data.access_token) {
        user.accessToken = response.data.success.data.access_token;
      }

      if (response.data.success.data.username) {
        user.username = response.data.success.data.username;
      }
    }

    const missingRequiredScope = !user.isAdmin && !user.isSuperAdmin


    if (missingRequiredScope) {
      toast("You are not authorized to login", { type: "error" });

      return null;
    }

    return user;
  } catch (error) {
    if (error.response) {
      toast(error.message.toString(), { type: "error" });
    } else if (error.request) {
      toast(error.message.toString(), { type: "error" });
    } else {
      toast(
        "Something happened in setting up the request that triggered an error",
        { type: "error" }
      );
    }

    return null;
  }
};

export const logout = async () => {
  try {
    const response = await customAxios().post("/user/logout");

    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAccessToken = () => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  if (user && user.accessToken) {
    return user.accessToken;
  }

  return null;
};
