import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-image-crop/dist/ReactCrop.css";

import App from "./App";
import muiTheme from "./config/muiTheme";

import { UserProvider } from "./contexts/UserContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider theme={muiTheme}>
    <UserProvider>
      <ToastContainer position="bottom-right" />

      <App />
    </UserProvider>
  </ThemeProvider>
);
